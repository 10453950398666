import React from 'react';

function Channel(props) {
  const { item = {}, onSelect, channelConfig } = props;
  let chName = item.name;
  const userCount = item.user_count;
  let maxUser = channelConfig.g_user_limit;
  // 判断是否是私密房间
  let chFlag = '#';
  if (item.name.indexOf('_') > -1) {
    maxUser = channelConfig.s_user_limit;
    chFlag = <div className="channel-list-lefticon"></div>;
    chName = item.name.replace('_', '').slice(0, 2) + '*'.repeat(6);
  }
  return (
    <div
      className="channel-list-item"
      key={item.name + item.user_count}
      onClick={() => {
        onSelect && onSelect(item.name);
      }}
    >
      <div className="channel-list-left">{chFlag}</div>
      <div className="channel-list-right">
        {chName}&nbsp;&nbsp;
        {userCount >= maxUser ? 'MAX' : userCount}
      </div>
    </div>
  );
}

export default Channel;
