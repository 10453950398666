import firebase from 'firebase/app';
import 'firebase/firestore';

// Initialize Cloud Firestore through Firebase
firebase.initializeApp({
  apiKey: 'AIzaSyDG7HoOzt233bCkSl9RGx_45gKAKXcNPgc',
  authDomain: 'walkietalkie-a6b37.firebaseapp.com',
  projectId: 'walkietalkie-a6b37'
});

const db = firebase.firestore();

// channels:公有频道，secrets:私密频道
export const getChannels = (channelType = 'channels') => {
  // 只取一次
  return db
    .collection(channelType)
    .get()
    .then(querySnapshot => {
      let data = [];
      querySnapshot.forEach(doc => {
        // console.log(`${doc.id} => ${doc.data()}`);
        const item = doc.data();
        data.push({
          name: item.name,
          user_count: item.user_count
        });
      });
      return data;
    });
};

// 获取频道配置
export const getChannelConfig = () => {
  return db
    .collection('settings')
    .doc('channel_config')
    .get()
    .then(doc => {
      if (doc.exists) {
        return doc.data();
      } else {
        return {};
      }
    })
    .catch(error => {
      console.error('Error getting config:', error)
    });
};

export default db;
