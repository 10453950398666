import './styles/topbar.scss';
import React from 'react';
import { download } from '../config/utils';
import { gaEvent } from '../config/ReactGA';

import logoPng from '../images/logo.png';

export default function TopBar({ showTopBar, onClick }) {
  return (
    <div
      className="top-bar"
      style={{ display: showTopBar ? 'flex' : 'none' }}
      onClick={() => {
        gaEvent('download', 'web_download_banner');
        onClick && onClick();
        download();
      }}
    >
      <img className="top-bar-left" src={logoPng} alt="walkie-talkie" />
      <div className="top-bar-middle">
        <p className="top-bar-middle-t">Open in APP</p>
        <p className="top-bar-middle-d">
          Enjoy a better experience and function
        </p>
      </div>
      <div className="top-bar-right">Open</div>
    </div>
  );
}
