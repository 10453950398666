import './styles/entermodal.scss';
import React from 'react';
import ReactModal from 'react-modal';
import { gaEvent } from '../config/ReactGA';

ReactModal.setAppElement('#root');

function EnterSecretModal(props) {
  const { showModal, closeModal, onCancel, onOk } = props;
  return (
    <ReactModal
      isOpen={showModal}
      onRequestClose={() => {
        gaEvent('user_action', 'secret_modal', 'close');
        closeModal && closeModal();
      }}
      className="custom-modal"
    >
      <p className="enter-secret-modal-t">Enter the Secret Channel?</p>
      <p className="enter-secret-modal-d">
        This passcode can enter a secret channel, enter now?
      </p>
      <div className="enter-secret-modal-action">
        <div
          className="modal-btn cancel"
          onClick={() => {
            onCancel && onCancel();
          }}
        >
          Cancel
        </div>
        <div
          className="modal-btn ok"
          onClick={() => {
            onOk && onOk();
          }}
        >
          OK
        </div>
      </div>
    </ReactModal>
  );
}

export default EnterSecretModal;
