import './App.scss';
import React from 'react';
import { connect } from 'react-redux';
import AgoraRTC from 'agora-rtc-sdk';
import {
  appID,
  roleConfig,
  screenDesConfig,
  timePassed
} from './config/appConfig';
import { getChannels, getChannelConfig } from './api/firebase';
import {
  subPublicUpdate,
  subPrivateUpdate,
  updateUserCount,
  updateJoinedPrivateChs,
  setChannelConfig
} from './actions/channel';
import {
  addView,
  removeView,
  getLocationSearch,
  formatJoinedPrivate,
  checkIsMobile
} from './config/utils';
import { gaEvent } from './config/ReactGA';
import ChannelItem from './components/ChannelItem';
import TopBar from './components/TopBar';
import EnterSecretModal from './components/EnterSecretModal';
import CreateModal from './components/CreateModal';

import dotPng from './images/dot.png';
import logoPng from './images/logo.png';

// build为线上包的时候只打印ERROR级别的log信息
// https://docs.agora.io/cn/Voice/API%20Reference/web/modules/agorartc.logger.html
if (process.env.NODE_ENV === 'production') {
  AgoraRTC.Logger.setLogLevel(3);
}

// rtc object
let rtc = {
  client: null,
  joined: false,
  published: false,
  localStream: null,
  remoteStreams: [],
  params: {}
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.unsubPublic = null;
    this.unsubPrivate = null;
    this.topbarTimer = null;
    this.isMobile = false;
    this.state = {
      connected: false,
      speaking: false,
      channel: 'WELCOME',
      channelShowValue: 'WELCOME'
    };
  }

  async componentDidMount() {
    this.isMobile = checkIsMobile();
    this.props.dispatch(setChannelConfig());
    // 通过调用从onSnapshot返回的函数来执行此操作，取消监听firestore
    this.unsubPublic = this.props.dispatch(subPublicUpdate());
    this.unsubPrivate = this.props.dispatch(subPrivateUpdate());
    getChannelConfig();
    this.initClient();
    this.subRemoteStream();
    const search = window.location.search;
    if (search) {
      this.queryToJoinChannel(search);
    }
  }

  componentWillUnmount() {
    this.unsubPublic && this.unsubPublic();
    this.unsubPrivate && this.unsubPrivate();
    // document.body.addEventListener("mousedown", () => {});
    if (this.state.connected) {
      this.leaveChannel();
    }
  }

  fetchChs = async (channelType = 'channels') => {
    try {
      const data = await getChannels(channelType);
      // console.error('data:', data);
      return data;
    } catch (error) {
      console.error('error:', error);
    }
  };

  queryToJoinChannel = async search => {
    const { channel, passcode } = getLocationSearch(search);
    // console.error('search:', channel, passcode);
    if (passcode) {
      const privateChName = '_' + passcode;
      const privateChsData = await this.fetchChs('secrets');
      const { joinedPrivateChs } = this.props;
      let newJoinedChs = [...joinedPrivateChs];
      if (joinedPrivateChs.length) {
        newJoinedChs = formatJoinedPrivate(joinedPrivateChs, privateChsData);
      }
      const isExist = privateChsData.find(item => item.name === privateChName);
      if (isExist) {
        this.setState({
          channel: privateChName,
          channelShowValue: privateChName
        });
        this.joinChannel(privateChName, isExist.user_count);
        newJoinedChs = [...new Set([...newJoinedChs, passcode])];
        this.props.dispatch(updateJoinedPrivateChs(newJoinedChs));
      } else if (newJoinedChs.length !== joinedPrivateChs.length) {
        this.props.dispatch(updateJoinedPrivateChs(newJoinedChs));
      }
    } else if (channel) {
      const publicChsData = await this.fetchChs();
      const isExist = publicChsData.find(item => item.name === channel);
      if (isExist) {
        this.setState({ channel, channelShowValue: channel });
        this.joinChannel(channel, isExist.user_count);
      }
    }
  };

  initClient = () => {
    // Create a client
    rtc.client = AgoraRTC.createClient({ mode: 'live', codec: 'h264' });
    // Initialize the client
    rtc.client.init(
      appID,
      function () {
        console.log('init success');
      },
      err => {
        console.error(err);
      }
    );
  };

  subRemoteStream = () => {
    // 1. 监听 "stream-added" 事件，当有远端流加入时订阅该流
    rtc.client.on('stream-added', function (evt) {
      var remoteStream = evt.stream;
      var id = remoteStream.getId();
      if (id !== rtc.params.uid) {
        rtc.client.subscribe(remoteStream, function (err) {
          console.log('stream subscribe failed', err);
        });
      }
      // console.log('stream-added remote-uid: ', id);
    });
    // 2. 监听 "stream-subscribed" 事件，订阅成功后播放远端流
    rtc.client.on('stream-subscribed', function (evt) {
      var remoteStream = evt.stream;
      rtc.remoteStreams.push(remoteStream);
      var id = remoteStream.getId();
      // Add a view for the remote stream.
      addView(id);
      // Play the remote stream.
      remoteStream.play('audio_' + id);
      // console.log('stream-subscribed remote-uid: ', id);
    });
    // 3. 监听 "stream-removed" 事件，当远端流被移除时（例如远端用户调用了 Stream.unpublish）， 停止播放该流并移除它的画面
    rtc.client.on('stream-removed', function (evt) {
      var remoteStream = evt.stream;
      var id = remoteStream.getId();
      // Stop playing the remote stream.
      remoteStream.stop('audio_' + id);
      // Remove the view of the remote stream.
      removeView(id);
      rtc.remoteStreams = rtc.remoteStreams.filter(
        stream => stream.getId() !== id
      );
      // console.log('stream-removed remote-uid: ', id);
    });
  };

  joinChannel = (channel = '', userCount = 0) => {
    if (channel.length < 2) {
      return;
    }
    const { channelConfig } = this.props;
    let maxUser = channelConfig.g_user_limit;
    if (channel.indexOf('_') > -1) {
      maxUser = channelConfig.s_user_limit;
    }
    if (userCount >= maxUser) {
      this.setState({ screenInfo: screenDesConfig.maxUser });
      return;
    }
    if (!this.state.showTopBar && this.isMobile) {
      this.topbarTimer = setTimeout(() => {
        this.setState({ showTopBar: true });
      }, timePassed);
    }
    let q = '';
    if (/^_/.test(channel)) {
      q = `?passcode=${channel.replace('_', '')}`;
    } else {
      q = `?channel=${channel}`;
    }
    window.history.pushState(null, null, q);
    this.props.dispatch(updateUserCount(channel, 1));
    this.setState({
      channel,
      channelShowValue: channel
    });
    // Join a channel
    rtc.client.join(
      null,
      channel,
      null,
      uid => {
        // console.error('join channel success, uid: ' + uid);
        rtc.params.uid = uid;
        // 进入频道时就初始化一个伪音频，目的是为了获取麦克风权限，解决Safari浏览器下默认音频autuplay不播放
        rtc.localStream = AgoraRTC.createStream({
          streamID: rtc.params.uid,
          audio: true,
          video: false,
          screen: false
        });
        rtc.localStream.init(
          function () {
            console.log('init local stream success');
            rtc.localStream = null;
            rtc.client.setClientRole(roleConfig.audience);
          },
          function (err) {
            console.error('init local stream failed ', err);
          }
        );
        this.setState({ connected: true });
      },
      function (err) {
        console.error('client join failed', err);
      }
    );
  };

  leaveChannel = callback => {
    if (this.topbarTimer) {
      clearTimeout(this.topbarTimer);
      this.topbarTimer = null;
    }
    if (this.state.screenInfo) {
      this.setState({ screenInfo: '' });
    }
    // Leave the channel
    this.props.dispatch(updateUserCount(this.state.channel, -1));
    rtc.client.leave(
      () => {
        // Stop playing the local stream
        rtc.localStream && rtc.localStream.stop();
        // Close the local stream
        rtc.localStream && rtc.localStream.close();
        // Stop playing the remote streams and remove the views
        while (rtc.remoteStreams.length > 0) {
          var stream = rtc.remoteStreams.shift();
          var id = stream.getId();
          // stream.stop();
          removeView(id);
        }
        // console.log('client leaves channel success');
        this.setState({
          connected: false,
          speaking: false
        });
        callback && callback();
      },
      function (err) {
        console.log('channel leave failed');
        console.error(err);
      }
    );
  };

  handleClickSwitch = (channel = '', showUserCount) => {
    if (channel.length < 2) {
      return;
    }
    gaEvent('user_action', 'connect', channel);
    if (!this.state.connected) {
      if (channel.indexOf('_') === -1) {
        const { privateChs } = this.props;
        const isExist = privateChs.find(item => item.name === '_' + channel);
        if (isExist) {
          this.handleEnterSecretModal();
          return;
        }
      }
      this.joinChannel(channel, showUserCount);
    } else {
      this.leaveChannel();
    }
  };

  setClientRole = (role = 'audience') => {
    // 主播（"host"）还是观众（"audience"）
    rtc.client.setClientRole(role);
  };

  startSpeaking = () => {
    if (!this.state.connected) return;
    const { channelConfig } = this.props;
    let maxSpeak = channelConfig.g_speaker_limit;
    if (this.state.channel.indexOf('_') > -1) {
      maxSpeak = channelConfig.s_speaker_limit;
    }
    if (rtc.remoteStreams.length >= maxSpeak) {
      this.setState({ screenInfo: screenDesConfig.micMax });
      if (!this.maxMicTimer) {
        this.maxMicTimer = setTimeout(() => {
          this.setState({ screenInfo: '' });
          this.maxMicTimer = null;
        }, 3000);
      }
      return;
    } else if (this.state.screenInfo) {
      this.setState({ screenInfo: '' });
      if (this.maxMicTimer) {
        clearTimeout(this.maxMicTimer);
        this.maxMicTimer = null;
      }
    }
    this.setState({ speaking: true });
    this.setClientRole(roleConfig.host);
    addView(rtc.params.uid);
    // 1. Create a local stream
    rtc.localStream = AgoraRTC.createStream({
      streamID: rtc.params.uid,
      audio: true,
      video: false,
      screen: false
    });
    // 2. Initialize the local stream
    rtc.localStream.init(
      function () {
        console.log('init local stream success');
        document.getElementById('audio_begin').play();
        // play stream with html element id "local_stream"
        rtc.localStream &&
          rtc.localStream.play &&
          rtc.localStream.play('audio_' + rtc.params.uid);
        // 3. Publish the local stream
        rtc.client.publish(rtc.localStream, function (err) {
          console.error('publish failed');
          console.error(err);
        });
      },
      function (err) {
        console.error('init local stream failed ', err);
      }
    );
  };

  endSpeaking = () => {
    if (!this.state.connected) return;
    this.setState({ speaking: false });
    this.setClientRole(roleConfig.audience);
    rtc.localStream = null;
    document.getElementById('audio_end').play();
    removeView(rtc.params.uid);
  };

  soundPlay = () => {
    if (!this.state.connected) {
      return;
    }
    gaEvent('user_action', 'music', this.state.channel);
    const { channelConfig } = this.props;
    let maxSpeak = channelConfig.g_speaker_limit;
    if (this.state.channel.indexOf('_') > -1) {
      maxSpeak = channelConfig.s_speaker_limit;
    }
    if (rtc.remoteStreams.length >= maxSpeak) {
      return;
    }
    // this.setClientRole(roleConfig.host);
    addView(rtc.params.uid);
    // 1. Create a local stream
    rtc.localStream = AgoraRTC.createStream({
      streamID: rtc.params.uid,
      audio: true,
      video: false,
      screen: false
    });
    // 2. Initialize the local stream
    rtc.localStream.init(
      () => {
        document.getElementById('audio_call').play();
        // play stream with html element id "local_stream"
        // rtc.localStream.play('audio_' + rtc.params.uid);
        // 3. Publish the local stream
        rtc.client.publish(rtc.localStream, function (err) {
          console.error('publish failed');
          console.error(err);
        });
      },
      function (err) {
        console.error('init local stream failed ', err);
      }
    );
    if (this.soundTimer) {
      return;
    }
    this.soundTimer = setTimeout(() => {
      clearTimeout(this.soundTimer);
      this.soundTimer = null;
      this.setClientRole(roleConfig.audience);
      rtc.localStream = null;
      removeView(rtc.params.uid);
    }, 500);
  };

  prevChannel = () => {
    if (this.state.screenInfo) {
      this.setState({ screenInfo: '' });
    }
    const curCh = this.state.channel;
    const { publicChs, privateChs, joinedPrivateChs } = this.props;
    if (this.state.connected) {
      this.leaveChannel();
    }
    let channelList = [...publicChs];
    if (joinedPrivateChs.length && privateChs.length) {
      channelList = publicChs.concat(
        privateChs.filter(item =>
          joinedPrivateChs.includes(item.name.replace('_', ''))
        )
      );
    }
    let curIndex = channelList.findIndex(item => item.name === curCh);
    let prevIndex = curIndex - 1;
    if (curIndex === 0) {
      prevIndex = channelList.length - 1;
    }
    const channel = channelList[prevIndex].name;
    gaEvent('user_action', 'channel_up', channel);
    this.setState({ channel, channelShowValue: channel });
  };

  nextChannel = () => {
    if (this.state.screenInfo) {
      this.setState({ screenInfo: '' });
    }
    const curCh = this.state.channel;
    const { publicChs, privateChs, joinedPrivateChs } = this.props;
    if (this.state.connected) {
      this.leaveChannel();
    }
    let channelList = [...publicChs];
    if (joinedPrivateChs.length && privateChs.length) {
      channelList = publicChs.concat(
        privateChs.filter(item =>
          joinedPrivateChs.includes(item.name.replace('_', ''))
        )
      );
    }
    let curIndex = channelList.findIndex(item => item.name === curCh);
    let nextIndex = curIndex + 1;
    if (curIndex === channelList.length - 1) {
      nextIndex = 0;
    }
    const channel = channelList[nextIndex].name;
    gaEvent('user_action', 'channel_down', channel);
    this.setState({ channel, channelShowValue: channel });
  };

  handleChange = e => {
    const value = e.target.value.trim().toUpperCase();
    if (/^[0-9A-Z]{0,8}$/.test(value)) {
      this.setState({ channel: value, channelShowValue: value });
      if (this.state.connected) {
        this.leaveChannel();
      }
      if (this.state.screenInfo) {
        this.setState({ screenInfo: '' });
      }
    }
  };

  handleShowChannel = () => {
    gaEvent('user_action', 'channel_list');
    this.setState({
      showChList: true,
      channelShowValue: ''
    });
    if (this.state.screenInfo) {
      this.setState({ screenInfo: '' });
    }
  };

  handleCloseChannel = () => {
    this.setState({ showChList: false });
    if (!this.state.channelShowValue) {
      this.setState({ channelShowValue: this.state.channel });
    }
  };

  handleSelectChannel = channel => {
    gaEvent('user_action', 'channel_choice', channel);
    // console.error('channel:', channel);
    if (this.state.connected) {
      this.leaveChannel();
    }
    this.setState({ channel, showChList: false, channelShowValue: channel });
    if (this.state.screenInfo) {
      this.setState({ screenInfo: '' });
    }
  };

  renderMicIcon = () => {
    const { speaking, screenInfo } = this.state;
    let attachClass = speaking ? 'speaking' : '';
    if (screenInfo === screenDesConfig.micMax) {
      attachClass = 'mic-max';
    }
    return <i className={`screen-mic ${attachClass}`}></i>;
  };
  // 处理code存在于私密频道时的相关逻辑
  handleEnterSecretModal = () => {
    this.setState({ showEnterSecretModal: !this.state.showEnterSecretModal });
  };
  // 新建频道
  handleCreateModal = () => {
    this.setState({ showCreateModal: !this.state.showCreateModal });
  };

  render() {
    const {
      publicChs = [],
      privateChs = [],
      joinedPrivateChs = [],
      channelConfig
    } = this.props;
    const {
      connected,
      speaking,
      channel,
      channelShowValue,
      showChList,
      screenInfo
    } = this.state;
    let showUserCount = 0;
    const ch = publicChs.concat(privateChs).find(item => item.name === channel);
    if (ch) {
      showUserCount = ch.user_count;
    }

    let channelList = [...publicChs];
    if (joinedPrivateChs.length && privateChs.length) {
      channelList = publicChs.concat(
        privateChs.filter(item =>
          joinedPrivateChs.includes(item.name.replace('_', ''))
        )
      );
    }

    let attachClass = connected ? ' connected' : ' disconnected';
    let maxUser = channelConfig.g_user_limit;
    // 判断是否是私密频道
    if (channel.indexOf('_') > -1) {
      attachClass += ' private';
      maxUser = channelConfig.s_user_limit;
    }
    // 格式化屏幕的状态文案和人数
    let screenStatus = connected ? 'CONNECTED' : 'DISCONNECTED';
    let screenUser = showUserCount >= maxUser ? 'MAX' : showUserCount;
    if (screenInfo) {
      screenStatus = screenInfo;
    }
    // 格式化input里的value
    let inputV = channelShowValue;
    if (channelShowValue.indexOf('_') > -1) {
      inputV = channelShowValue.replace('_', '').slice(0, 2) + '*'.repeat(6);
    }
    return (
      <div className="app">
        <TopBar
          showTopBar={this.state.showTopBar}
          onClick={this.leaveChannel}
        />
        <div className={`splash-screen${publicChs.length ? ' fadeOut' : ''}`}>
          <div className="icon-logo">
            <img src={logoPng} alt="Walkie Talkie" />
          </div>
        </div>
        <div className="container-talkie">
          <div className={`container-main ${attachClass}`}>
            <div className="container-screen">
              <div className="container-screen-inner">
                <div className="container-screen-row">
                  <div className="screen-status">{screenStatus}</div>
                  <div className="screen-user">
                    {connected && this.renderMicIcon()}
                    {screenUser}
                  </div>
                </div>
                <div className="container-screen-row">
                  <div className="screen-bottomleft">
                    {/^_/.test(channel) ? (
                      <div className="private-icon"></div>
                    ) : (
                      '#'
                    )}
                  </div>
                  <input
                    ref={ref => (this.myInput = ref)}
                    style={{ width: '70%' }}
                    id="selectChannel"
                    className="screen-channel"
                    type="string"
                    maxLength={8}
                    placeholder="CREATE NEW"
                    autoComplete="off"
                    // value={channelShowValue.replace('_', '')}
                    value={inputV}
                    onChange={e => this.handleChange(e)}
                    onFocus={this.handleShowChannel}
                    onBlur={() => {
                      setTimeout(() => {
                        this.handleCloseChannel();
                      }, 200);
                    }}
                  />
                </div>
                <div className={`container-screen-bg`}></div>
              </div>
            </div>
            <div className="container-btns">
              <div className="container-btns-left">
                <div className="btn-up" onClick={this.prevChannel}></div>
                <div className="btn-down" onClick={this.nextChannel}></div>
              </div>
              <div className="container-btns-center">
                <div
                  className={`btn-sound${!connected ? ' disabled' : ''}`}
                  onClick={this.soundPlay}
                >
                  <div className="btn-sound-bg"></div>
                </div>
                <div
                  className="btn-create-private"
                  onClick={() => {
                    gaEvent('user_action', 'create_modal');
                    this.handleCreateModal();
                  }}
                ></div>
              </div>
              <div className="container-btns-right">
                <div
                  className={`btn-switch${connected ? '-on' : ''}`}
                  onClick={() => {
                    this.handleClickSwitch(channel, showUserCount);
                  }}
                ></div>
              </div>
            </div>
            <div
              className="channel-list"
              style={{ display: showChList ? 'block' : 'none' }}
            >
              {channelList &&
                channelList.length &&
                channelList.map(item => (
                  <ChannelItem
                    item={item}
                    key={item.name}
                    onSelect={this.handleSelectChannel}
                    channelConfig={channelConfig}
                  />
                ))}
            </div>
          </div>
          <div
            className={`container-speak${!connected ? ' disabled' : ''}${
              speaking ? ' speaking' : ''
            }`}
            // onMouseDown={this.startSpeaking}
            // onMouseUp={this.endSpeaking}
            onClick={() => {
              // disable callin
              // if (this.state.speaking) {
              //   this.endSpeaking();
              // } else {
              //   this.startSpeaking();
              // }
            }}
          >
            <div className="push-to-talk">
              {speaking ? 'TALKING...' : 'DOWNLOAD TO TALK'}
            </div>
            <img src={dotPng} alt="speak" />
          </div>
          <div className="store">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=walkie.talkie.talk&referrer=utm_source%3Dweb%26utm_medium%3Dbanner_bottom%26utm_term%3Dweb_install%26utm_campaign%3Dweb_android_install"
              onClick={() => {
                this.leaveChannel();
                gaEvent('download', 'web_download_android');
              }}
            >
              <img
                src="https://walkietalkie.live/images/google-store.png"
                alt="Google Play"
                title="Google Play"
              />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://apps.apple.com/us/app/walkie-talkie-talk-to-friends/id1505959099?pt=118301901&ct=web_ios_install&mt=8"
              onClick={() => {
                this.leaveChannel();
                gaEvent('download', 'web_download_ios');
              }}
            >
              <img
                src="https://walkietalkie.live/images/apple-store.png"
                alt="App Store"
                title="App Store"
              />
            </a>
          </div>
        </div>
        <EnterSecretModal
          showModal={this.state.showEnterSecretModal}
          closeModal={this.handleEnterSecretModal}
          onCancel={() => {
            gaEvent('user_action', 'secret_modal', channel);
            this.handleEnterSecretModal();
            this.joinChannel(channel, showUserCount);
          }}
          onOk={() => {
            const newCh = '_' + channel;
            gaEvent('user_action', 'secret_modal', newCh);
            this.setState({
              channel: newCh,
              channelShowValue: newCh
            });
            this.handleEnterSecretModal();
            let joinedChs = [...this.props.joinedPrivateChs];
            // 存在localStore里的不加"_"
            joinedChs.push(channel);
            this.props.dispatch(updateJoinedPrivateChs(joinedChs));
            this.joinChannel(newCh, showUserCount);
          }}
        />
        <CreateModal
          showModal={this.state.showCreateModal}
          closeModal={this.handleCreateModal}
          connected={connected}
          joinChannel={this.joinChannel}
          leaveChannel={this.leaveChannel}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ channel }) => {
  return {
    publicChs: channel.publicChs,
    privateChs: channel.privateChs,
    joinedPrivateChs: channel.joinedPrivateChs,
    channelConfig: channel.channelConfig
  };
};

export default connect(mapStateToProps)(App);
