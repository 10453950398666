import './styles/createmodal.scss';
import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { download } from '../config/utils';
import { gaEvent } from '../config/ReactGA';

ReactModal.setAppElement('#root');

const config = {
  0: {
    t: 'Create Global Channel',
    d: 'Create a channel visible to everyone and chat with them',
    btnTxt: 'Create'
  },
  1: {
    t: 'Create Secret Channel',
    d: 'Create secret channel to chat with your friends',
    btnTxt: 'Create New'
  }
};

function ModalContent({ tabKey, value, handleChange, handleCreate }) {
  return (
    <div className={`tab-pane ${tabKey === 0 ? 'global' : 'secret'}`}>
      <p className="tab-pane-t">{config[tabKey].t}</p>
      <p className="tab-pane-d">{config[tabKey].d}</p>
      {tabKey === 0 && (
        <input
          className="tab-pane-input"
          type="string"
          maxLength={8}
          placeholder="Channel Name"
          autoComplete="off"
          value={value}
          onChange={e => handleChange && handleChange(e)}
        />
      )}
      <div
        className="tab-pane-btn create"
        onClick={() => {
          if (tabKey === 0) {
            handleCreate && handleCreate();
          } else {
            gaEvent('download', 'web_download_secret');
            download();
          }
        }}
      >
        {config[tabKey].btnTxt}
      </div>
    </div>
  );
}

function CreateModal(props) {
  const { showModal, closeModal, connected, joinChannel, leaveChannel } = props;
  const [tabKey, setTabKey] = useState(0);
  const [value, setValue] = useState('');

  const handleClose = () => {
    closeModal && closeModal();
    setTabKey(0);
    setValue('');
  };

  const handleSelect = key => {
    if (key === tabKey) {
      return;
    }
    setTabKey(key);
  };

  const handleChange = e => {
    const v = e.target.value.trim().toUpperCase();
    if (/^[0-9A-Z]{0,8}$/.test(v)) {
      setValue(v);
    }
  };

  const handleCreate = () => {
    if (value.length < 2) {
      return;
    }
    gaEvent('user_action', 'channel_create_new', value);
    if (connected) {
      leaveChannel(() => {
        joinChannel(value);
        handleClose();
      });
    } else {
      joinChannel && joinChannel(value);
      handleClose();
    }
  };
  return (
    <ReactModal
      isOpen={showModal}
      onRequestClose={handleClose}
      className="custom-modal"
    >
      <div className="create-modal-content">
        <div className="tab-nav">
          <div
            className={`tabs-tab ${tabKey === 0 ? 'selected' : ''}`}
            onClick={() => handleSelect(0)}
          >
            GLOBAL
          </div>
          <div
            className={`tabs-tab ${tabKey === 1 ? 'selected' : ''}`}
            onClick={() => handleSelect(1)}
          >
            SECRET
          </div>
          <div
            className="tabs-ink-bar"
            style={{ transform: `translate3d(${tabKey * 80}px, 0px, 0px)` }}
          ></div>
        </div>
        <ModalContent
          tabKey={tabKey}
          value={value}
          handleChange={handleChange}
          handleCreate={handleCreate}
        />
      </div>
    </ReactModal>
  );
}

export default CreateModal;
