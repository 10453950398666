import db, { getChannelConfig } from '../api/firebase';
import { localConstant, channelConfig } from '../config/appConfig';

export const types = {
  update_channels: 'update_channels',
  set_channel_config: 'set_channel_config'
};
// 订阅公共频道
export const subPublicUpdate = () => {
  return dispatch => {
    // 此方法会监听firestore变化，重复输出data
    return db.collection('channels').onSnapshot(querySnapshot => {
      let data = [];
      querySnapshot.forEach(doc => {
        // console.log(`${doc.id} => ${doc.data()}`);
        const item = doc.data();
        data.push({
          name: item.name,
          user_count: item.user_count
        });
      });
      data.sort((a, b) => b.user_count - a.user_count);
      // console.error('publicChs:', data);
      dispatch({ type: types.update_channels, data: { publicChs: data } });
    });
  };
};
// 订阅私密频道
export const subPrivateUpdate = () => {
  return (dispatch, getState) => {
    // 此方法会监听firestore变化，重复输出data
    return db.collection('secrets').onSnapshot(querySnapshot => {
      let data = [];
      querySnapshot.forEach(doc => {
        // console.log(`${doc.id} => ${doc.data()}`);
        const item = doc.data();
        data.push({
          name: item.name,
          user_count: item.user_count
        });
      });
      // console.error('privateChs:', data);
      const prevPrivateChs = getState().channel.privateChs;
      if (!prevPrivateChs.length && !data.length) {
        return;
      }
      // let joinedPrivate =
      //   window.localStorage.getItem(localConstant.joined_private) || '[]';
      // joinedPrivate = JSON.parse(joinedPrivate);
      let joinedPrivateChs = getState().channel.joinedPrivateChs;
      if (joinedPrivateChs.length) {
        const privateChs = data.map(item => item.name);
        const filterChs = joinedPrivateChs.filter(item =>
          privateChs.includes('_' + item)
        );
        if (filterChs.length !== joinedPrivateChs.length) {
          window.localStorage.setItem(
            localConstant.joined_private,
            JSON.stringify(filterChs)
          );
          joinedPrivateChs = filterChs;
        }
      }
      dispatch({
        type: types.update_channels,
        data: { privateChs: data, joinedPrivateChs }
      });
    });
  };
};

// type为true时 user_count加1，反之减1
export const updateUserCount = (channelName, count) => {
  return (dispatch, getState) => {
    let chs = [];
    let chType = 'public';
    if (/^_/.test(channelName)) {
      chType = 'private';
      chs = getState().channel.privateChs;
    } else {
      chs = getState().channel.publicChs;
    }
    const chIndex = chs.findIndex(item => item.name === channelName);
    if (chIndex < 0) {
      return;
    }
    chs[chIndex].user_count =
      chs[chIndex].user_count + count < 0 ? 0 : chs[chIndex].user_count + count;
    if (chType === 'public') {
      dispatch({ type: types.update_channels, data: { publicChs: chs } });
    } else {
      dispatch({ type: types.update_channels, data: { privateChs: chs } });
    }
  };
};
// 更新加入过的私密频道
export const updateJoinedPrivateChs = array => {
  window.localStorage.setItem(
    localConstant.joined_private,
    JSON.stringify(array)
  );
  return dispatch => {
    dispatch({
      type: types.update_channels,
      data: { joinedPrivateChs: array }
    });
  };
};
// 获取频道配置
export const setChannelConfig = () => {
  return async dispatch => {
    try {
      const data = await getChannelConfig();
      dispatch({
        type: types.set_channel_config,
        data
      });
    } catch (error) {
      console.error(error);
    }
  };
};

const INITIAL_STATE = {
  // 公有频道
  publicChs: [],
  // 私有频道
  privateChs: [],
  // 加入过的私有频道: ['ABC', 'BBB']
  joinedPrivateChs: JSON.parse(
    localStorage.getItem(localConstant.joined_private) || '[]'
  ),
  channelConfig
};
// state是当时当刻的快照
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.update_channels: {
      return {
        ...state,
        ...action.data
      };
    }
    case types.set_channel_config: {
      return {
        ...state,
        channelConfig: {
          ...state.channelConfig,
          ...action.data
        }
      };
    }
    default:
      return state;
  }
}
