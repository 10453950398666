import ReactGA from 'react-ga';

export const disabledGA = (id = '') => {
  if (typeof window !== 'undefined') {
    window['ga-disable-' + id] = true;
  } else {
    throw new Error('window is undefined when disabledGA.');
  }
};

if (typeof window !== 'undefined') {
  ReactGA.initialize('UA-66609102-35');
  if (process.env.NODE_ENV === 'development') {
    disabledGA('UA-66609102-35');
  }
}

export const gaEvent = (c = '', a = '', l = '') => {
  console.log('ga event:', c, a, l);
  ReactGA.event({
    category: String(c),
    action: String(a),
    label: String(l)
  });
};

export default ReactGA;
