export const addView = (id, show) => {
  if (!document.getElementById('audio_' + id)) {
    var audio = document.createElement('audio');
    audio.id = 'audio_' + id;
    audio.autoplay = true;
    document.body.appendChild(audio);
  }
};

export const removeView = id => {
  var el = document.getElementById('audio_' + id);
  if (el) {
    el.remove();
  }
};

export const getLocationSearch = str => {
  let query = {};
  if (!str) {
    return query;
  }
  const array = str[0] === '?' ? str.slice(1).split('&') : str.split('&');
  if (array.length) {
    array.forEach(item => {
      const [key, value] = item.split('=');
      query[key] = value;
    });
  }
  return query;
};

export const download = () => {
  if (/iOS|iPhone|iPod|iPad|Mac/i.test(window.navigator.userAgent)) {
    window.open(
      'https://apps.apple.com/us/app/walkie-talkie-talk-to-friends/id1505959099?pt=118301901&ct=web_ios_install&mt=8'
    );
  } else {
    window.open(
      'https://play.google.com/store/apps/details?id=walkie.talkie.talk&referrer=utm_source%3Dweb%26utm_medium%3Dbanner_bottom%26utm_term%3Dweb_install%26utm_campaign%3Dweb_android_install'
    );
  }
};
// localChs: ['ABC']; privateChs: [{ name: 'ABC', user_count: 0 }]
export const formatJoinedPrivate = (localChs, privateChs) => {
  privateChs = privateChs.map(item => item.name);
  return localChs.filter(ch => privateChs.includes('_' + ch));
};

export const checkIsMobile = () => {
  const userAgent = navigator.userAgent;
  const agents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPod',
    'iPad'
  ];
  for (let i = 0; i < agents.length; i++) {
    if (userAgent.indexOf(agents[i]) >= 0) return true;
  }
  return false;
};
