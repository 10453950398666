// 声网SDK需要的APPID
export const appID = '06040a98af684c5f9306350bbce03acb';
// 设置直播间用户身份
export const roleConfig = {
  host: 'host',
  audience: 'audience'
};
// 浏览器localStorage变量名
export const localConstant = {
  joined_private: 'joined_private'
};
// create new channel
export const createNew = {
  name: 'Create new',
  type: 'create'
};
// 公共频道最大人数限制 20, 同时讲话 5
// 私密频道最大人数限制 50, 同时讲话 10
export const channelConfig = {
  g_speaker_limit: 5,
  g_user_limit: 20,
  s_speaker_limit: 10,
  s_user_limit: 50
};

export const screenDesConfig = {
  maxUser: 'ERROR MAX USERS',
  micMax: 'MIC MAX'
}

// 加入频道X分钟后顶部弹出引导下载的bar
export const timePassed = 2 * 60 * 1000;
